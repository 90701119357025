<template>
    <!--static-->
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"

    >
        <template #modal-title>
            {{action == 'editing'?$t('label_add_to_recipient_database'):$t('label_add_to_recipient_database')}}
        </template>
        <template #default="{ hide }">


            <validation-observer
                    tag="form"
                    class="p-1"
                    #default="{handleSubmit, reset, invalid}"
                    :ref="PREFIX + '_FORM'"

            >

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_type')"
                        rules="required"
                >
                    <b-form-group
                            :label="$t('label_type')"
                            :state="getValidationState(validationProps)"
                    >
                        <v-select
                                v-model="itemData.type"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="memberTypes"
                                :reduce="val => val.alias"
                                label="name"
                                :clearable="false"
                                :placeholder="$t('label_select')"
                        >
                            <template v-slot:option="option">
                                <span class="">{{ $t(option.label) }}</span>
                            </template>
                            <template v-slot:selected-option="option">
                                <!--<span :class="option.icon"></span>-->
                                <span class="">{{ $t(option.label) }}</span>
                            </template>
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>


                <validation-provider
                        #default="validationProps"
                        :name="$t('label_sender')"
                        rules="required"
                        slim

                >
                    <b-form-group
                            :label="$t('label_sender')"

                    >
                        <b-form-input

                                v-model="itemData.name"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_sender')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_address')"
                        rules="required"
                        slim

                >
                    <b-form-group
                            :label="$t('label_address')"

                    >
                        <b-form-input

                                v-model="itemData.address"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_address')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <div class="d-flex justify-content-end mt-2">
                    <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">
                        {{$t('label_cancel')}}
                    </button>

                    <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                            class="btn btn-primary waves-effect waves-light">{{(action ==
                        'editing')?$t('label_submit'):$t('label_submit')}}
                    </button>
                </div>

            </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
        BFormTextarea,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BInputGroupPrepend,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import {MAIL_PREFIX as MODULE_PREFIX, PARTICIPANT_PREFIX as PREFIX} from './../moduleHelper'

    export default {
        components: {

            BForm,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupAppend,
            BFormCheckbox,
            BInputGroupPrepend,

            ValidationProvider,
            ValidationObserver,
            vSelect
        },
        props: ['editedItem'],
        data() {
            return {
                MODULE_PREFIX,
                PREFIX,

                action: 'adding',

                blankItemData: {
                    id: 0,
                    type: '',
                    name: '',
                    address: '',
                    // description: '',
                    // deadline_at: '',
                    // contact_id: 0
                },

                itemData: {},
                memberTypes: [
                    {alias: "sender", label: 'label_sender'},
                    {alias: "recipient", label: 'label_recipients'}
                ]

            }
        },

        methods: {

            initData() {
                let item = this.editedItem;
                if (item) {
                    this.action = 'editing';
                    item = Object.assign({}, this.editedItem);
                    this.itemData = item;
                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData)
                }
            },
            resetForm() {
                this.itemData = Object.assign({}, this.blankItemData);
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit() {
                if (this.action == 'adding') {
                    this.async('post', this.MODULE_PREFIX + '_' + this.PREFIX + 's', this.itemData, function (resp) {
                        this.$emit('item-added', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });

                } else {
                    this.async('put', this.MODULE_PREFIX + '_' + this.PREFIX + 's/' + this.itemData.id, this.itemData, function (resp) {
                        this.$emit('item-edited', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });
                }
            }
        },

    }
</script>