<template>

    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"

    >
        <template #modal-title>
            {{action == 'editing'?$t('label_sending__receiving_confirmation'):$t('label_sending__receiving_confirmation')}}
        </template>
        <template #default="{ hide }">


        <validation-observer
                tag="form"
                class="p-1"
                #default="{handleSubmit, reset, invalid}"
                :ref="PREFIX + '_FORM'"

        >

            <validation-provider
                    #default="validationProps"
                    :name="$t('label_confirmation_type')"
                    rules="required"
            >
                <b-form-group
                        :label="$t('label_confirmation_type')"
                        :state="getValidationState(validationProps)"
                >
                    <v-select
                            v-model="itemData.confirm_action"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="confirmActions"
                            :reduce="val => val.alias"
                            label="name"
                            :clearable="false"
                            :placeholder="$t('label_select')"
                    >
                        <template v-slot:option="option">
                            <span class="">{{ $t(option.trans_index) }}</span>
                        </template>
                        <template v-slot:selected-option="option">
                            <!--<span :class="option.icon"></span>-->
                            <span class="">{{ $t(option.trans_index) }}</span>
                        </template>
                        <template v-slot:no-options="option">
                            {{$t('label_no_items')}}
                        </template>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                        {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>


            <validation-provider

                    #default="validationProps"
                    :name="$t('label_set_sending__receiving_date')"
                    rules="required"
                    class="flex-grow-1"
                    slim
            >
                <b-form-group
                        :label="$t('label_set_sending__receiving_date')"
                        label-for="meeting_type_id"
                >

                    <custom-date-picker
                            :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                            :locale="$store.state.appConfig.locale"
                            :placeholder="$t('label_DD-MM-YYYY')"
                            :value="itemData.date_at"

                            @input="itemData.date_at = $event"
                    >
                        <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                        <div slot="label">
                            <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                        </div>
                    </custom-date-picker>

                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                        {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>

            <validation-provider
                    class="mt-2"
                    #default="validationProps"
                    :name="$t('label_to_ocr')"
                    slim
            >
                <b-form-group
                        label=""

                >
                    <!--:checked="itemData.google_sync"-->
                    <b-form-checkbox
                            id="m-confirm-ocr"
                            value="1"
                            unchecked-value="0"
                            v-model="itemData.ocr"

                    >
                        <label for="m-confirm-ocr">{{ $t('label_to_ocr') }}</label>
                    </b-form-checkbox>
                </b-form-group>
            </validation-provider>

            <validation-provider
                    class="mt-2"
                    :name="$t('label_document')"
                    slim
                    #default="validationProps"

            >

                <file-input

                        v-model="document"
                        class="file--uploader drop-block mb-3"
                        :max-files-count='10'
                        :required="true"
                        allowed-extensions="*"
                        :max-file-size='15'
                        :url="''"
                >
                    <template #uploadBody>
                        <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                        <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                        {{$t('label_add_file')}}
                    </template>

                    <template #errorBug="fileErrors">

                        <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>
                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </template>
                </file-input>


            </validation-provider>

            <div class="d-flex justify-content-end mt-2">
                <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">{{$t('label_cancel')}}</button>

                <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                        class="btn btn-primary waves-effect waves-light">{{(action == 'editing')?$t('label_submit'):$t('label_submit')}}</button>
            </div>
        </validation-observer>
        </template>




    </b-modal>
</template>


<script>
    import { ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
         BFormTextarea,
        BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroupAppend, BInputGroup, BFormCheckbox, BInputGroupPrepend,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import {serialize} from 'object-to-formdata';
    import  fileInput from '@/views/components/fileUploadInput'
    import {MAIL_PREFIX  as MODULE_PREFIX} from './../moduleHelper'

    export default {
        components: {

            BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BInputGroup, BInputGroupAppend, BFormCheckbox, BInputGroupPrepend,

            ValidationProvider,
            ValidationObserver,
            vSelect,
            fileInput
        },
        props:['editedItem','confirmActions'],
        data() {
            return {
                MODULE_PREFIX,
                PREFIX: 'confirmation',

                action: 'adding',

                blankItemData: {
                    id:0,
                    confirm_action: '',
                    date_at:'',
                    ocr:'',
                },

                itemData: {},


                document:[],

            }
        },

        methods: {

            initData(){
                if(this.editItem){
                    this.itemData = Object.assign({},this.blankItemData);
                    this.itemData.id = this.editedItem.id;

                }

            },
            resetForm() {
                this.itemData = Object.assign({},this.blankItemData);
                this.document = [];
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit(){
                // if(this.action == 'adding') {
                let formData = new FormData();
                formData = serialize(this.itemData, {}, formData);

                if (this.document.length > 0) {
                    for(let i = 0; i< this.document.length; i++){
                        formData.append('document[]', this.document[i]);
                    }
                }
                    this.async('put', 'mail_confirm/' + this.editedItem.id, formData, function(resp){
                        this.$emit('item-added',{});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });

            }
        },

    }
</script>